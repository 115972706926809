import { LatLng } from "leaflet";
import { ITruMap } from "../types/ITruMap";
import { useEffect, useState } from "react";

type InfoResponse = {
  data: FeatureInfo[];
  isLoading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
};

export type FeatureInfo = {
  ID: number;
  layerID: number;
  name: string;
  properties: { [key: string]: string | number | null };
};

/**
 * Ask the tile server for a list of features under the given point.
 */
export function useInfoRequestFeatures(truMap: ITruMap, latlng?: LatLng): InfoResponse {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (truMap && latlng) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [truMap, latlng]);

  async function fetchData() {
    const url =
      truMap.getTileUrl() +
      "/info/" +
      truMap.getTileLayerID() +
      "/" +
      truMap.getZoom() +
      "/" +
      latlng?.lat +
      "/" +
      latlng?.lng;

    setIsLoading(true);
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const body = await response.json();
      setIsLoading(false);
      setData(body.data);
      setError(null);
    } catch (error) {
      setError(`Could not fetch features: ${error}`);
      setIsLoading(false);
    }
  }

  return {
    data,
    isLoading,
    error,
  };
}
