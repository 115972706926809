import { UnitOfTime } from "moment";

type Nouns = Record<number, UnitOfTime>;
type Adverbs = Record<number, string>;

const nouns: Nouns = {
  1: "day",
  2: "week",
  3: "month",
  4: "quarter",
  5: "year",
};

const adverbs: Adverbs = {
  0: "One-Time",
  1: "Daily",
  2: "Weekly",
  3: "Monthly",
  4: "Quarterly",
  5: "Annually",
};

/**
 * Gets a list of interval nouns (day, week, month, quarter, etc...)
 * @param exclude A list of nouns to exclude
 * @returns The array of requested nouns
 */
export function getNouns(exclude?: string | string[]): string[] {
  return excludeFromList(nouns, exclude);
}

/**
 * Gets a list of interval adverbs (daily, weekly, monthly, quarterly, etc...)
 * @param exclude A list of adverbs to exclude
 * @returns The array of requested adverbs
 */
export function getAdverbs(exclude?: string | string[]): string[] {
  return excludeFromList(adverbs, exclude);
}

/**
 * Gets the value of a noun
 * @param noun The noun to find
 * @returns The key corresponding to the given noun, or null if not found
 */
export function getNoun(noun: number): string | null {
  return nouns[noun];
}

/**
 * Gets the value of an adverb
 * @param adverb The adverb to find
 * @returns The key corresponding to the given adverb, or null if not found
 */
export function getAdverb(adverb: string): number | null {
  return findByValue(adverbs, adverb);
}

/**
 * Finds the key for a given value in the provided list.
 * @param list The list to search in
 * @param search The value to find
 * @returns The key associated with the given value or null if not found
 */
function findByValue(list: Nouns | Adverbs, search: string): number | null {
  const entry = Object.entries(list).find(([, value]) => value === search);
  return entry ? parseInt(entry[0], 10) : null;
}

/**
 * Excludes specified values from the given list.
 * @param list The list to filter
 * @param exclude A string or array of strings to exclude
 * @returns An array of the remaining values
 */
function excludeFromList(list: Nouns | Adverbs, exclude?: string | string[]): string[] {
  const excludeSet = new Set(Array.isArray(exclude) ? exclude : [exclude]);
  return Object.values(list).filter((value) => !excludeSet.has(value));
}
