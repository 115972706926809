import { useMemo, useState } from "react";
import { ICollection, Type } from "../types/ICollection";
import { IMap } from "../types/IMap";
import { classNameMapper } from "../utils/classNameMapper";
import { Field, Formik } from "formik";
import { useSaveFeaturePropertiesMutation } from "../slices/apiSlice";
import { withReduxProvider } from "../services/withReduxProvider";
import { FeatureInfo } from "../hooks/useInfoRequestFeatures";
import { isFloat, isInteger, shouldShowAsEnum } from "../data/collection";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { invokeForcedReload } from "../slices/pagesSlice";
import * as Yup from "yup";
import { useHasMapAccess } from "../pages/TruTerritory/hasMapAccess";

interface IProps {
  feature: FeatureInfo;
  collection: ICollection;
  map: IMap;
  close: () => void;
}

const MapPointDetailsComponent: React.FC<IProps & JSX.IntrinsicAttributes> = ({
  feature: initialFeature,
  collection,
  map,
  close,
}: IProps) => {
  const hasMapAccess = useHasMapAccess();
  const [saveFeatureProperties] = useSaveFeaturePropertiesMutation();
  const dispatch = useAppDispatch();

  const [editing, setEditing] = useState(false);
  const [copySuccessful, setCopySuccessful] = useState(false);

  // On first load, use unmodified feature info
  const [feature, setFeature] = useState<FeatureInfo>(initialFeature);

  const editable =
    (collection.type == "master" || collection.type == "writable") &&
    hasMapAccess("collaborate") &&
    map.editable &&
    map.editable[collection.ID] &&
    (map.isPublic === undefined ? true : map.isPublic);
  const hasEditableColumns = !!collection.properties.find(
    (prop) => !prop["system"] && !prop["protected"] && !prop["hidden"]
  );

  const formattedDates: { [key: string]: string } = useMemo(() => {
    return collection.properties
      .filter((prop) => prop["type"] == "timestamptz")
      .reduce(
        (dict, prop) => ({
          ...dict,
          [prop["column"]]:
            feature.properties[prop["column"]] && new Date(feature.properties[prop["column"]]!).toLocaleString(),
        }),
        {}
      );
  }, [feature.properties, collection.properties]);

  async function copyCsvToClipboard() {
    await navigator.clipboard.writeText(
      makeCSV(
        feature.properties,
        collection.properties.map((p) => p.column)
      )
    );
    setCopySuccessful(true);
    setTimeout(() => {
      setCopySuccessful(false);
    }, 1000);
  }

  function makeCSV(properties: { [key: string]: unknown }, keys: string[]): string {
    const values: string[] = [];

    for (const key of keys) {
      values.push('"' + (properties[key] === null ? "" : properties[key]?.toString().replace(/"/g, '""')) + '"');
    }

    return keys.join(",") + "\n" + values.join(",");
  }

  async function save(values: { [key: string]: string | number | null }) {
    const body = {
      ...feature,
      properties: values,
      mapID: map.ID,
      workspaceID: map.workspaceID,
    };

    // Find dirty properties so that we can update the _label backend-computed property which is now out of date.
    const labelOverrides = Object.keys(feature.properties)
      .filter((key) => feature.properties[key] !== values[key])
      .reduce((obj, key) => ({ ...obj, [`${key}_label`]: values[key] }), {});

    const result = await saveFeatureProperties(body).unwrap();
    setFeature({ ...feature, properties: { ...values, ...labelOverrides } });
    setEditing(false);

    // Refresh the map.
    dispatch(invokeForcedReload());

    return result;
  }

  const validation = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const shape: Record<string, any> = {};
    for (const p of collection.properties) {
      if (!p.required) continue;

      if (isInteger(p.type)) {
        shape[p.column] = Yup.number().integer().nullable();
      } else if (isFloat(p.type)) {
        shape[p.column] = Yup.number().nullable();
      }
    }

    return Yup.object(shape);
  }, [collection.properties]);

  return (
    <div className="map-point-detail">
      {!editing && (
        <div className="controls">
          {editable && hasEditableColumns && (
            <button
              type="button"
              className="btn leaflet-btn plain edit"
              onClick={(e) => {
                setEditing(true);
                // I don't know why, but if this click event causes this element to cease existing (because the !editing condition above),
                // the click propogates to leaflet as if it's outside the popup, causing the popup to close.
                e.stopPropagation();
              }}
              title="Edit this feature"
            ></button>
          )}
          <div className="copy-holder">
            <div>
              <div className={classNameMapper({ "tooltip top fade": true, in: copySuccessful })}>
                <div className="tooltip-arrow"></div>
                <div className="tooltip-inner">Copied!</div>
              </div>
            </div>
            <button
              className="btn leaflet-btn copy"
              title="Copy properties as CSV"
              onClick={copyCsvToClipboard}
            ></button>
          </div>
          <button className="btn delete-3" onClick={close} title="Close popup"></button>
        </div>
      )}
      <Formik initialValues={feature.properties} onSubmit={save} validationSchema={validation}>
        {({ submitForm, isSubmitting, handleSubmit, errors, touched, setFieldValue }) => (
          <form className="form-horizontal" name="point-edit" onSubmit={handleSubmit}>
            <table>
              <tbody>
                {collection.properties
                  .filter((p) => !p.hidden && !((p.system || p.protected) && editing))
                  .map((p) => (
                    <tr key={p.column}>
                      <td title={p.column}>{p.name}:</td>
                      <td
                        title={(
                          formattedDates[p.column] ||
                          feature.properties[p.column + "_label"] ||
                          feature.properties[p.column] ||
                          ""
                        ).toString()}
                      >
                        {editing && (
                          <>
                            {(p.type == Type.Text && shouldShowAsEnum(p) && (
                              <div
                                className="select-holder"
                                style={{ border: "1px solid #666", height: "auto", width: "133px" }}
                              >
                                <Field name={p.column} as="select" style={{ marginTop: 0, color: "#333" }}>
                                  <option value="">Select...</option>
                                  {p.allowedValues
                                    ?.filter((v) => typeof v == "string")
                                    .map((val, i) => (
                                      <option value={val} key={i}>
                                        {val}
                                      </option>
                                    ))}
                                </Field>
                              </div>
                            )) || (
                              <Field
                                type="text"
                                name={p.column}
                                className={classNameMapper({ invalid: !!errors[p.column] && !!touched[p.column] })}
                                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                  const val = e.currentTarget.value;
                                  if (val === "" && (isInteger(p.type) || isFloat(p.type))) {
                                    setFieldValue(p.column, null);
                                  } else {
                                    setFieldValue(p.column, val);
                                  }
                                }}
                                title={
                                  (feature.properties[p.column + "_label"] || feature.properties[p.column]) +
                                  " (" +
                                  p.type +
                                  ")"
                                }
                              />
                            )}
                          </>
                        )}
                        {!editing && (
                          <span>
                            {formattedDates[p.column] ||
                              feature.properties[p.column + "_label"] ||
                              feature.properties[p.column]}
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {editing && (
              <div className="edit-controls">
                <button className="btn leaflet-btn" type="submit" disabled={isSubmitting} onClick={submitForm}>
                  {!isSubmitting && "Save"}
                  {isSubmitting && (
                    <>
                      <span className="small-loading-indicator"></span>
                      <span>Saving...</span>
                    </>
                  )}
                </button>
                {!isSubmitting && (
                  <span
                    className="btn leaflet-btn plain"
                    onClick={(e) => {
                      setEditing(false);
                      e.stopPropagation();
                    }}
                  >
                    Cancel
                  </span>
                )}
              </div>
            )}
          </form>
        )}
      </Formik>
    </div>
  );
};

export const MapPointDetails = withReduxProvider(MapPointDetailsComponent);
