/**
 * Global application configuration
 */
var config = {};

config.protocol = "https:";

config.DOMAIN = "epilists.com";
config.ROOT = config.protocol + "//app." + config.DOMAIN;
config.API_ROOT = config.protocol + "//tardis." + config.DOMAIN + "/api";
config.DOCS_ROOT = config.protocol + "//docs." + config.DOMAIN;
config.REACT_ROOT = config.ROOT + "/react";
config.SHARING_ROOT = config.protocol + "//" + config.DOMAIN + "/maps";
config.LEGACY_DOMAIN = config.protocol + "//reports." + config.DOMAIN;
config.DEV = false;
config.TILE_URL = config.protocol + "//tiles." + config.DOMAIN;
config.TEGOLA_URL = config.protocol + "//vectors." + config.DOMAIN;

config.PUBLIC_GA_ID = "UA-44954143-7";
config.APP_GA_ID = "UA-44954143-6";

// Google geocoder API key
config.GEOCODE_KEY = "AIzaSyCEXyWNhTVaGBUR_3imFlrAfeBB8lgVOWA";

// Settings for Fine Uploader
config.S3_BUCKET_URL = "https://epiphany-files-epilists.s3.amazonaws.com";
config.S3_ACCESS_KEY = "AKIAJFUFLGAMTWYDT3IA";
config.S3_REGION = "us-west-1";

// The mapbox tile info
config.MAPBOX_USER = "geostrategies";
config.MAPBOX_STYLE_ID = "ckhlcedni05ps19rq0a0xd7ls";
config.MAPBOX_TILE_SIZE = 256;
config.MAPBOX_HIGHDPI = false;
config.MAPBOX_ACCESS_TOKEN = "pk.eyJ1IjoiZ2Vvc3RyYXRlZ2llcyIsImEiOiI0NC1DRVhrIn0.OIEcZz0WqPaMgsyKRu6ycg";

// The Datadog application id
config.DATADOG_APP_ID = "0d17233e-3182-4eb8-ae8c-ef7945d129e3";

config.THEME = process.env.THEME;

if (typeof module != "undefined" && module.exports) module.exports = config;
