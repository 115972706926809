import { useMemo } from "react";
import { useAppSelector } from "../../hooks/useAppSelector";
import { PermissionManager } from "../../services/permission-manager";
import { ISession } from "../../types/ISession";

export const hasMapAccess = (permissions: ISession["permissions"], entityID: number, type: string) => {
  const access = new PermissionManager(permissions || {});
  return access.hasAccessAbove("entity." + entityID + ".mapping", type);
};

export const useHasMapAccess = () => {
  const { session, entityID } = useAppSelector((state) => state.app) ?? {};

  return useMemo(() => (type: string) => entityID ? hasMapAccess.bind(null, session?.permissions ?? {}, entityID, type) : () => false, [entityID, session?.permissions]);
};
