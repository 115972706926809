import "./import-jquery";

import "jquery-ui/dist/jquery-ui";

import _ from "lodash";
window._ = _;

import L from "leaflet";
window.L = L;

L.Icon.Default.imagePath = "/";

import moment from "moment";
window.moment = moment;

import qq from "../node_modules/fine-uploader/lib/jquery/s3";
window.qq = qq;

import numeral from "numeral";
window.numeral = numeral;

import "jquery.nicescroll/dist/jquery.nicescroll.min";
import "../node_modules/jquery-ui-touch-punch/jquery.ui.touch-punch";
import "leaflet-draw";
import "../node_modules/@maplibre/maplibre-gl-leaflet/leaflet-maplibre-gl";

import "redactor";
import "redactor/plugins/fontcolor";
import "redactor/plugins/fontsize";
import "redactor/plugins/fullscreen";

// eslint-disable-next-line @typescript-eslint/no-require-imports
require("./pages/TruTerritory/L.contextMenu");
